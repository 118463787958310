import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';

const SoloGuidesBoC: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Battlefield of Chaos</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_boc.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Battlefield of Chaos</h1>
          <h2>
            Battlefield of Chaos mode and Gems in Solo Leveling: Arise
            explained.
          </h2>
          <p>
            Last updated: <strong>18/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Battlefield of Chaos" />
        <StaticImage
          src="../../../images/solo/generic/boc_1.webp"
          alt="Guide"
        />
        <p>
          The Battlefield of Chaos is a new feature added to the game where
          players can obtain gems. Gems are a new way to power up your character
          using the - you guessed it! - gem system, allowing you to customize
          what extra stats you can obtain.
        </p>
        <SectionHeader title="Gems" />
        <StaticImage
          src="../../../images/solo/generic/boc_2.webp"
          alt="Guide"
        />
        <p>Every gem color offers different stats:</p>
        <ul>
          <li>
            <strong>Red gems</strong> offer additional attack, attack % and
            healing given %,
          </li>
          <li>
            <strong>Blue gems</strong> offer Additional HP, hp% and healing
            received %,
          </li>
          <li>
            <strong>Green gems</strong> gives additional defense, defense % and
            damage reduction,
          </li>
          <li>
            <strong>Orange gems</strong> gives Precision, critical hit damage
            and defense penetration,
          </li>
          <li>
            <strong>Purple gems</strong> gives additional speed, additional mana
            and reduced mana consumption.
          </li>
        </ul>
        <p>
          The system is divided in circles and you cannot advance to the next
          one before filling the previous one with a gem of each type.
        </p>
        <p>
          For example you can see here I have 3 of Red, Blue and Green gems but
          only 2 for Yellow and Purple, so I cannot add more Red gems until I
          finish the previous ‘circle’. It’s important to note that when used,
          gems cannot be unequipped. You can replace the gems with new ones, but
          this means you will lose the gems you previously put in the slot.
        </p>
        <p>
          All units you possess are receiving the bonus from the gem system,
          meaning your overall power will increase significantly, allowing you
          to progress more easily.
        </p>
        <SectionHeader title="How do you get gems?" />
        <p>You can obtain gems in multiple ways:</p>
        <ul>
          <li>You can obtain gems from the Bond System.</li>
          <li>You can purchase gems from the Coin Exchange Shop.</li>
          <li>You can fuse Gems of a lower tier like you would with Runes.</li>
          <li>You can obtain gems from the Battlefield of Chaos.</li>
          <li>
            You can obtain gems with in-app purchases, as many deals now offer
            the option to get random tier 4 Gems.{' '}
          </li>
        </ul>
        <p>
          The different gem stages are open depending on the games and you
          always have 3 entries per day. Stages have battle missions as usual
          and award you with gems at its completion:
        </p>
        <StaticImage
          src="../../../images/solo/generic/boc_3.webp"
          alt="Guide"
        />
        <p>
          You fight 3 bosses back to back, so it’s important to have a balanced
          party with hunters (ideally) of the same element in order to get an
          easier time clearing. One of the missions requires to get support from
          2 or more hunters for the advantaged element, so it might be good to
          start building more hunters if you were not already. The bond system
          already offers great incentives to level up your roster, so it’s no
          coincidence they were released together!
        </p>
        <p>
          After getting your gems you might be wondering what stats you should
          focus on. The answer is: depends. If you are looking at the highest cp
          bonus, every hunter benefits differently from specific stats based on
          what they scale on, so there’s no ‘one stat fits all’ besides mana.
        </p>
        <p>
          <strong>
            First of all, we suggest filling all the slots using rank 1 or two
            gems that have useful bonuses.{' '}
          </strong>
        </p>
        <p>
          The cp bonus is too good to miss out on to push easier, plus you can
          always replace them as you get better runes. After filling all the
          circles you can start using gem fusing, however, the chance of success
          is very low and you might not even get the gems that you need, so it’s
          gonna take a lot of time to properly replace all your gems.
        </p>
        <ul>
          <li>
            For <strong>Red gems</strong>: Additional attack and attack % are
            both strong candidates. As you get higher base stats attack %
            becomes stronger, but there’s nothing wrong with using a high ranked
            additional attack rune.
          </li>
          <li>
            For <strong>Blue gems</strong>: HP% is the best stat to get it, as
            the high HP pool will give you a significant cp boost using a %
            bonus. Additional HP works but ideally you'd want to replace it with
            % bonuses here
          </li>
          <li>
            For <strong>Purple gems</strong>: Additional MP is the best
            candidate, considering you need a lot of mana to make 'reduced mana
            consumption' almost as good as just having more mana to begin with.
            The addition of hunters like Mirei and artifacts sets requiring to
            be above specific mana tresholds makes having a higher mana pool
            even more important. If you get a rank 4 gem, one or two reduced
            mana consumption gems could be slotted. Speed provides a decent cp
            boost but as of now, it's mostly for speedrunning content so take
            your own conclusions about how important it may be for you
          </li>
          <li>
            For <strong>Green gems</strong>: We suggest to just use additional
            defense gems. Defense % gems can be a nice placeholder and damage
            reduction gems didn't end up being as effectivee as they could have,
            but provide the highest cp boost.
          </li>
          <li>
            For <strong>Yellow gems</strong>: Precision and critical damage gems
            are your best choice here. Precision is helpful if you are running
            weapons at lower advancement levels (and admittedly, even if you are
            considering you don't want to invest points in perception) and
            critical damage is never a bad stat, provided you can crit. It also
            gives you more flexibility with your artifacts on the right side,
            allowing you to reach the suggested 180% crit damage soft cap much
            easier and focus on raising your attack instead. Defense penetration
            can be useful but as it seems the recent content focuses more on
            damage reduction and less on having defense, the value kinda dropped
            a bit
          </li>
        </ul>
        <p>
          As a small tip, the gem screen will not let you progress after
          finishing a circle. When that happens, just close the menu and open it
          out again and you’ll be able to start putting gems in the new circle!
        </p>
        <p>
          The game will soon give the option to reroll tier 4 gems - as soon as
          we get more info we'll update this page to give you the latest
          details!
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesBoC;

export const Head: React.FC = () => (
  <Seo
    title="Battlefield of Chaos | Solo Leveling: Arise | Prydwen Institute"
    description="Battlefield of Chaos mode and Gems in Solo Leveling: Arise explained."
    game="solo"
  />
);
